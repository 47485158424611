import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { id: "header" }
const _hoisted_2 = { id: "header-wrap" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "header-row" }
const _hoisted_5 = {
  id: "logo",
  class: "me-lg-5"
}
const _hoisted_6 = { href: "/" }
const _hoisted_7 = ["srcset", "src"]
const _hoisted_8 = { class: "header-misc ms-lg-2" }
const _hoisted_9 = { class: "header-misc-icon ps-lg-4" }
const _hoisted_10 = { class: "top-primary-menu" }
const _hoisted_11 = {
  key: 0,
  class: "menu-container",
  style: {"display":"block !important"}
}
const _hoisted_12 = {
  key: 1,
  class: "menu-container header-container",
  style: {"display":"block !important"}
}
const _hoisted_13 = { class: "menu-item" }
const _hoisted_14 = { class: "primary-menu main-primary-menu with-arrows" }
const _hoisted_15 = { class: "menu-container" }
const _hoisted_16 = ["href"]
const _hoisted_17 = ["href"]
const _hoisted_18 = {
  key: 2,
  class: "mega-menu-content"
}
const _hoisted_19 = { class: "row g-0 align-items-stretch" }
const _hoisted_20 = { class: "sub-menu-container mega-menu-column col" }
const _hoisted_21 = { class: "row d-md-flex p-md-4" }
const _hoisted_22 = { class: "col-md-6" }
const _hoisted_23 = ["href"]
const _hoisted_24 = { class: "mb-1" }
const _hoisted_25 = { class: "mb-0 fw-normal text-smaller text-black-50" }
const _hoisted_26 = { class: "col-md-6" }
const _hoisted_27 = ["href"]
const _hoisted_28 = { class: "mb-1" }
const _hoisted_29 = { class: "mb-0 fw-normal text-smaller text-black-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileThumbnail = _resolveComponent("ProfileThumbnail")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", _hoisted_6, [
              _createElementVNode("img", {
                class: "logo-default",
                srcset: `${_ctx.logoImage}`,
                src: _ctx.logoImage,
                alt: "Frame Logo",
                style: {"max-height":"70px","padding-top":"5px","padding-bottom":"5px"}
              }, null, 8, _hoisted_7)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                (!_ctx.isLoggedIn)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_11, _cache[0] || (_cache[0] = [
                      _createElementVNode("li", { class: "menu-item" }, [
                        _createElementVNode("a", {
                          class: "menu-link",
                          href: "/signIn"
                        }, [
                          _createElementVNode("div", null, "Sign In")
                        ])
                      ], -1)
                    ])))
                  : (_openBlock(), _createElementBlock("ul", _hoisted_12, [
                      _createElementVNode("li", _hoisted_13, [
                        _createVNode(_component_router_link, {
                          to: "/profile",
                          class: "menu-link"
                        }, {
                          default: _withCtx(() => [
                            (!_ctx.isApiCallInProgress)
                              ? (_openBlock(), _createBlock(_component_ProfileThumbnail, {
                                  key: 0,
                                  user: _ctx.user,
                                  notifications: _ctx.newNotifications,
                                  width: 40
                                }, null, 8, ["user", "notifications"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ])
                    ]))
              ])
            ])
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "primary-menu-trigger" }, [
            _createElementVNode("button", {
              class: "cnvs-hamburger",
              type: "button",
              title: "Open Mobile Menu"
            }, [
              _createElementVNode("span", { class: "cnvs-hamburger-box" }, [
                _createElementVNode("span", { class: "cnvs-hamburger-inner" })
              ])
            ])
          ], -1)),
          _createElementVNode("nav", _hoisted_14, [
            _createElementVNode("ul", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appConfigs.layout.navbar.pages, (page) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass({'menu-item mega-menu mega-menu-small': page.dropdown, 'menu-item': !page.dropdown}),
                  key: page.name
                }, [
                  (!page.dropdown)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        class: "menu-link",
                        href: page.route
                      }, [
                        _createElementVNode("div", null, _toDisplayString(page.name), 1)
                      ], 8, _hoisted_16))
                    : _createCommentVNode("", true),
                  (page.dropdown)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        class: "menu-link",
                        href: page.route
                      }, [
                        _createElementVNode("div", null, _toDisplayString(page.name), 1)
                      ], 8, _hoisted_17))
                    : _createCommentVNode("", true),
                  (page.dropdown)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("div", _hoisted_22, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(page.dropdown.slice(0, page.dropdown.length / 2), (category) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "menu-item mb-md-2",
                                    key: category.name
                                  }, [
                                    _createElementVNode("a", {
                                      href: category.route,
                                      class: "menu-link"
                                    }, [
                                      _createElementVNode("h4", _hoisted_24, _toDisplayString(category.name), 1),
                                      _createElementVNode("p", _hoisted_25, _toDisplayString(category.description), 1)
                                    ], 8, _hoisted_23)
                                  ]))
                                }), 128))
                              ]),
                              _createElementVNode("div", _hoisted_26, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(page.dropdown.slice(Math.floor(page.dropdown.length / 2)), (category) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "menu-item mb-md-2",
                                    key: category.name
                                  }, [
                                    _createElementVNode("a", {
                                      href: category.route,
                                      class: "menu-link"
                                    }, [
                                      _createElementVNode("h4", _hoisted_28, _toDisplayString(category.name), 1),
                                      _createElementVNode("p", _hoisted_29, _toDisplayString(category.description), 1)
                                    ], 8, _hoisted_27)
                                  ]))
                                }), 128))
                              ])
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ])
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("nav", { class: "primary-menu ms-lg-auto" }, [
            _createElementVNode("ul", { class: "menu-container menu-type-secondary" }, [
              _createElementVNode("li", { class: "menu-item" })
            ])
          ], -1))
        ])
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "header-wrap-clone" }, null, -1))
  ]))
}